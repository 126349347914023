import React from 'react';
import PropTypes from 'prop-types';
import { StyledCallToAction } from '../../../sharedComponents';
import { Text, Title, Row } from './index.style';
import { renderHTMLString } from '../../../../../../helpers';

export function AlertBannerDefault(props) {
  const { title, titleSize, text, ctaConfig, visualStyles, displayStyles } =
    props;

  return (
    <div className="container">
      <Row displayStyles={displayStyles} className="row">
        <div className={`col-xs-12 col-sm-${ctaConfig.shown ? 9 : 12}`}>
          <Title
            as={titleSize.toLowerCase()}
            displayStyles={displayStyles}
            visualStyles={visualStyles}
          >
            {title}
          </Title>
          <Text displayStyles={displayStyles} visualStyles={visualStyles}>
            {renderHTMLString(text)}
          </Text>
        </div>
        {ctaConfig.shown && (
          <div className="col-xs-12 col-sm-3">
            <StyledCallToAction
              size="tiny"
              look="hollow"
              mode="white"
              ctaConfig={ctaConfig}
              displayStyles={displayStyles}
            />
          </div>
        )}
      </Row>
    </div>
  );
}

AlertBannerDefault.propTypes = {
  title: PropTypes.string,
  titleSize: PropTypes.string,
  alertText: PropTypes.string,
  link: PropTypes.shape({
    type: PropTypes.string,
  }),
};

export default AlertBannerDefault;
