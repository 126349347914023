import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const Row = styled.div`
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles.elements.row)};
`;

export const Title = styled.h2`
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles.elements.heading)};
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles.elements.heading)};
`;

export const Text = styled.div`
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles.elements.paragraph)};
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles.elements.paragraph)};
`;
