import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { SiteBuilderModule } from '../sharedComponents';
import styled, { ThemeContext } from 'styled-components';
import { AlertBannerDefault } from './displayStyles';
import { renderStyledElementStyles } from '../../helpers';

const Wrapper = styled.div`
  ${({ theme, displayStyles }) =>
    renderStyledElementStyles(theme, displayStyles)};
  ${({ theme, visualStyles }) =>
    renderStyledElementStyles(theme, visualStyles)};
`;

export function AlertBannerModule(props) {
  const {
    section: {
      title,
      titleSize = 'h2',
      alertText: text,
      colorType = 'RED',
      link: ctaConfig = {},
      isLive: live,
      shown,
    } = {},
  } = props;

  const themeConfig = useContext(ThemeContext);

  const { alertBannerModule } = themeConfig.siteBuilderModules;
  const displayStyles = alertBannerModule.displayStyles.DEFAULT;
  const visualStyles = alertBannerModule.visualStyles[colorType];

  return !shown || !live ? null : (
    <SiteBuilderModule section={props.section}>
      <Wrapper displayStyles={alertBannerModule} visualStyles={visualStyles}>
        <AlertBannerDefault
          title={title}
          titleSize={titleSize}
          ctaConfig={ctaConfig}
          text={text}
          visualStyles={visualStyles}
          displayStyles={displayStyles}
        />
      </Wrapper>
    </SiteBuilderModule>
  );
}

AlertBannerModule.propTypes = {
  section: PropTypes.shape({
    type: PropTypes.string.isRequired,
    title: PropTypes.string,
    titleSize: PropTypes.string,
    alertText: PropTypes.string,
    colorType: PropTypes.oneOf(['RED', 'SECONDARY', 'DARKGREY']),
    link: PropTypes.shape({
      type: PropTypes.string,
    }),
    shown: PropTypes.bool,
    isLive: PropTypes.bool,
  }).isRequired,
};

export default AlertBannerModule;
